exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-compositing-js": () => import("./../../../src/pages/compositing.js" /* webpackChunkName: "component---src-pages-compositing-js" */),
  "component---src-pages-dreamcatchers-js": () => import("./../../../src/pages/dreamcatchers.js" /* webpackChunkName: "component---src-pages-dreamcatchers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lifedrawing-js": () => import("./../../../src/pages/lifedrawing.js" /* webpackChunkName: "component---src-pages-lifedrawing-js" */),
  "component---src-pages-mobilemenu-js": () => import("./../../../src/pages/mobilemenu.js" /* webpackChunkName: "component---src-pages-mobilemenu-js" */),
  "component---src-pages-personal-js": () => import("./../../../src/pages/personal.js" /* webpackChunkName: "component---src-pages-personal-js" */),
  "component---src-pages-shortfilms-js": () => import("./../../../src/pages/shortfilms.js" /* webpackChunkName: "component---src-pages-shortfilms-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-visdev-js": () => import("./../../../src/pages/visdev.js" /* webpackChunkName: "component---src-pages-visdev-js" */)
}

